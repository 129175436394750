/** @jsxImportSource theme-ui */
import { FC, useMemo } from 'react'
import {
  PrismicContactPageDataType,
  PrismicImageType,
} from 'root/graphql-types'
import { ml, sm } from 'src/gatsby-plugin-theme-ui/utils'
import { H1, Body } from 'src/components/atoms/text'
import { replaceHtml } from 'src/utils/htmlReactParser'
import { ResponsiveAsset } from 'src/components/organisms'
import { ImageProp } from 'src/utils/gatsby'
import { Grid } from 'src/components/atoms/layout'
import { motion } from 'framer-motion'
import {
  yOpacityProps,
  DELAY_AFTER_SHUTTER,
  STAGGER_TIME,
  OFFSET_CARDS_Y,
} from 'src/animations'
import SEO from 'src/components/organisms/SEO'
import { setActiveLink } from 'src/components/organisms/NavBar/NavBarDesktop'

type ContactPageProps = {
  pageContext: PrismicContactPageDataType & {
    seo_image_1200x1200: PrismicImageType
    alternate_languages: any
    lang: any
    title: string
  }
  path: string
}

const ContactPage: FC<ContactPageProps> = ({ pageContext, path }) => {
  const { title, contact_image_desktop, contact_image_mobile, description } =
    pageContext

  const descriptionHtml = useMemo(
    () => replaceHtml(description?.html || '', 'p', Body),
    [description?.html]
  )

  setActiveLink('contact')
  return (
    <div>
      <SEO
        title={pageContext?.seo_title}
        image={pageContext?.seo_image_1200x1200?.fluid?.src}
        description={pageContext?.seo_description}
        path={path}
        alternate_languages={pageContext.alternate_languages}
        lang={pageContext.lang}
      />
      <motion.div
        {...yOpacityProps({
          delay: DELAY_AFTER_SHUTTER,
          initialY: OFFSET_CARDS_Y,
        })}
      >
        <ResponsiveAsset
          imageDesktop={contact_image_desktop as ImageProp}
          imageMobile={contact_image_mobile as ImageProp}
          sx={{ mt: 'm' }}
        />
      </motion.div>
      <div>
        <motion.div
          {...yOpacityProps({ delay: DELAY_AFTER_SHUTTER + STAGGER_TIME })}
        >
          <Grid
            columns={sm(1, 2)}
            sx={{
              mb: ml('l', 'xxl'),
            }}
          >
            <div>
              <H1
                sx={{
                  position: ml('initial', 'sticky'),
                  top: ml(0, 'xxl'),
                  mt: ml('m', 'xxl'),
                }}
              >
                {title}
              </H1>
            </div>
            <div
              sx={{
                mt: ml('s', 'xxl'),
              }}
            >
              {descriptionHtml}
            </div>
          </Grid>
        </motion.div>
      </div>
    </div>
  )
}

export default ContactPage
